import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Investment Banking" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-23">
        <div class="format_text">
          <h1>Investment Banking</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_17007979-Wall-Street-Capital-Markets2-300x225.jpeg"
                alt="Wall Street"
              />
            </span>
            As Senior Vice President of the New York-based investment banking
            firm Donaldson, Lufkin &amp; Jenrette, Larry has enjoyed a diverse
            corporate finance practice, having sole client responsibility for
            the origination, negotiation and processing of $billions (current
            market value) of merger and acquisition transactions, and work on
            public equity offerings for both traded and going-public
            situations.&nbsp;His other transaction experience includes: venture
            capital, real estate, public finance, and substantial private debt
            and equity placement.
          </p>
          <p>The following are representative transactions:</p>
          <p>
            Restructured (during 2008) an International Emerging Growth Company
            mutual fund specializing in the securities of small-capitalized
            growth companies (many engaged in business in the People’s Republic
            of China).&nbsp;This engagement involved the sale of millions of
            shares of publicly-traded common stock and considerable Rule 144
            compliance.
          </p>
          <p>
            Initiated the sale of Alliance Mortgage Corporation, Jacksonville,
            Florida ($ 2+ billion in mortgage loan servicing) to{" "}
            <a href="http://www.o-i.com/">Owens-Illinois, Inc</a>
            .&nbsp;He had sole client responsibility at Donaldson, Lufkin &amp;
            Jenrette for this transaction approximating $1 billion+ (current
            market value).
          </p>
          <p>
            Arranged initial capitalization, served as founding director,
            established corporate governance schematic (recruited majority of
            board of directors) and drafted many of the early-stage financing
            documents for an oil and gas exploration and production company
            having a current market capitalization approximating $4 billion.
          </p>
          <p class="breakhere">
            Initiated transaction and participated in all drafting sessions for
            the Initial Public Offering of an Orange County, California
            financial services company (
            <a href="http://www.davispolk.com/">
              Davis Polk &amp; Wardwell LLP.
            </a>{" "}
            represented Donaldson, Lufkin &amp; Jenrette; while{" "}
            <a href="http://www.lw.com/">Latham &amp; Watkins</a> [Los Angeles]
            represented the issuer).
          </p>
          <p>
            Sole responsibility for a dozen or so separate corporate asset
            sales, each in excess of $100 million (over $5 billion total).
          </p>
          <p>
            Initiated transaction and participated in all drafting sessions for
            secondary public offering of common stock in a Florida-based
            diversified financial services company.
          </p>
          <p>
            Served as Chairman of the Committee of Independent Directors in the
            sale of Orange Julius, Inc. (800+ units world-wide) to International
            Dairy Queen, Inc. now owned by Warren Buffett’s{" "}
            <a href="http://www.berkshirehathaway.com/">
              Berkshire Hathaway, Inc
            </a>
            .&nbsp;Winthrop, Stimpson Putnam &amp; Roberts (now{" "}
            <a href="http://www.pillsburylaw.com/">Pillsbury Winthrop</a>) acted
            as counsel to the committee.
          </p>
          <p>
            Responsible for most of the drafting for numerous private placement
            memorandum in conjunction with the sale of debt and equity
            securities (transaction sizes ranging generally from $1.5 million to
            $14.5 million dollars).&nbsp;Fortunate to work closely over many
            years with George Michaely (fmr. Chief Counsel to the Division of
            Corporation Finance at the SEC) on many of these transactions.
          </p>
          <p>
            A serial entrepreneur, responsible for perhaps a dozen or so
            corporate start-ups (typically serving as chairman or vice chairman
            of the board of directors).
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
